<template>
  <div v-if="buttonDownloadLinks">
    <section class="btn-download container">
      <div class="content content-centered content-roomy trimmed-width-3 d-grid">
        <div class="group-download d-flex flex-column">
          <a
            v-for="(buttonLinks, idx) in buttonDownloadLinks" :key="idx"
            class="btn btn-max mb-3"
            :alt="buttonLinks.fields.seoTitle"
            :href="buttonLinks.fields.link ? buttonLinks.fields.link : buttonLinks.fields.media.fields.file.url"
          >
            <i :class="buttonLinks.fields.icon ? buttonLinks.fields.icon : 'icon-cloud-download'"></i>
            <b>{{ buttonLinks.fields.title ? buttonLinks.fields.title : buttonLinks.fields.media.fields.title}}</b>
          </a>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
  export default {
    name: 'buttonDownloadApp',
    props: ["buttonDownloadLinks"]
  }
</script>

import _ from 'lodash';

const getKeys = (obj) => {
  let data = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key] !== null && typeof obj[key] === 'object') {
      if (key === 'fields') {
        if (obj[key] !== null && typeof obj[key] === 'object') {
          if (key === 'fields') {
            data = Object.assign(data, obj);
          }
        }
      }
      getKeys(obj[key]);
    }
  });
  return data;
};

const camelToDash = (str) => str[0].toLowerCase() + str.slice(1, str.length).replace(/[A-Z]/g, (letter) => `-${letter.toLowerCase()}`);

const updateAttributeValue = (node, key, value) => {
  if (/seo-title/.test(camelToDash(key))) {
    node.setAttribute('title', value);
  }

  if (/link/.test(camelToDash(key))) {
    node.setAttribute('href', value);
  }

  if (/background-image/.test(camelToDash(key))) {
    const { style } = node;
    style.backgroundImage = `url(${value.fields.file.url})`;
  }
};

export const updateInnerHTML = (data) => {
  _.forEach(getKeys(data), (obj) => {
    Object.entries(obj).forEach(([key, value]) => {
      const node = document.querySelector(`[data-ctf-${camelToDash(key)}]`);
      if (node) {
        if (/seo-title|link|background-image/.test(camelToDash(key))) {
          updateAttributeValue(node, key, value);
        } else {
          node.innerHTML = value;
        }
      }
    });
  });
};

export const checkDataStructure = (data) => {
  if (Array.isArray(data)) {
    _.each(data, updateInnerHTML);
  } else {
    updateInnerHTML(data);
  }
};

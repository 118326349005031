import $ from 'jquery';

// smooth scrolling
$(document).on('click', 'a[href^="#"]', function smoothSroll(event) {
  event.preventDefault();

  $('html, body').animate(
    {
      scrollTop: $($.attr(this, 'href')).offset().top,
    },
    {
      duration: 500,
      complete: () => {
        $('.d-none').css('display', 'none');
        $('.trust-start').css('display', 'none');
        $('[data-track="form-get-started-cta"]').css('display', 'none');
        $('#form-content').css('display', '');
      },
    },
  );
});

// Hide Menu on Scroll
let prevScrollpos = window.pageYOffset;
window.onscroll = function hideNavbar() {
  const currentScrollPos = window.pageYOffset;

  if (prevScrollpos > currentScrollPos) {
    document.getElementById('navbar').style.top = '0';
  } else {
    document.getElementById('navbar').style.top = '-64px';
  }
  prevScrollpos = currentScrollPos;
};

// Open Summary dropdown
$(document).on('click', '.closure h2', () => {
  $('.summary.dropdown').toggleClass('show');
});

// Open FAQ dropdown
$(document).on('click', '.faq .dropdown-parent', function openDropdown() {
  $(this).closest('.dropdown').toggleClass('show-first-level');
});

// Open FAQ dropdown
$(document).on('click', '.faq .dropdown .faq-first-level', function openSecondDropdown() {
  $(this).toggleClass('show');
});

$(document).ready(() => {
  // get current URL path and assign 'active' class
  const { pathname } = window.location;
  $(`li.nav-item > a[href="${pathname}"]`).parent().addClass('active');
});

<template>
  <div>
    <footer class="footer-v2 container bg-brwn-lght">
      <div class="content content-narrow content-centered trimmed-width-5 d-flex flex-column column-gap-5">
        <div class="group-description mb-3 mb-lg-0">
          <h5 data-ctf-footer-headline></h5>
          <p class="font-xs" data-ctf-footer-description></p>
        </div>

        <div
          class="group-links d-flex flex-column column-gap-5 flex-md-row"
          v-if="dataWasDownloadedSuccessfully"
        >
          <div class="contact" v-for="link in companyLinks" :key="link.headline">
            <h6>{{ link.headline }}</h6>
            <ul class="list-vertical">
              <li v-for="link in link.links" :key="link.fields.title">
                <a :href="link.fields.link" :title="link.fields.seoTitle">{{ link.fields.title }}</a>
              </li>
            </ul>
          </div>
          <div class="social social-icons" v-for="link in socialLinks" :key="link.headline">
            <h6>{{ link.headline }}</h6>
            <ul class="list-vertical">
              <li v-for="link in link.links" :key="link.fields.title">
                <a :href="link.fields.link" :title="link.fields.seoTitle" rel="nofollow" target="_blank">
                  {{ link.fields.title }}
                </a>
              </li>
            </ul>
          </div>
          <div class="links" v-for="link in legalLinks" :key="link.headline">
            <h6>{{ link.headline }}</h6>
            <ul class="list-vertical">
              <li v-for="link in link.links" :key="link.fields.title">
                <a :href="link.fields.link" :title="link.fields.seoTitle" rel="nofollow">
                  {{ link.fields.title }}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="content content-low content-centered trimmed-width-5 pt-0">
        <div class="colophon text-xs-center mb-3 mb-lg-0">
          <p class="font-xs">&copy; 2021 Göken, Pollak &amp; Partner - Wirtschaftsprüfung,
            betriebswirtschaftliche Beratung &amp; Steuerberatung aus einer Hand: GPP.</p>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
  import { getContent } from '@/javascripts/contentful';
  import _ from 'lodash'

  export default {
    name: 'footerApp',
    data() {
      return {
        dataWasDownloadedSuccessfully: false,
        socialLinks: [],
        legalLinks: [],
        companyLinks: []
      }
    },

    async mounted() {
      const { fields } = await getContent('4fwswlrBhIjh0rsal71aor')
        _.each(fields.footerNav, async (nav) => {
          const { fields: link } = await getContent(nav.sys.id);
          if (link) {
            if (link.headline === 'Unternehmen')
              this.companyLinks.push(link)

            if (link.headline === 'Social')
              this.socialLinks.push(link);

            if (link.headline === 'Rechtliches')
              this.legalLinks.push(link);

            this.dataWasDownloadedSuccessfully = true
          }
        })
    },
  }
</script>
